import request from '@/utils/http.js';

export function queryMatchList(options) {
    return request({
        url: '/businessPro/queryMatchCenter',
        method: 'post',
        data: options
    })
}

export function queryMatchDetailUrl(options) {
    return request({
        url: '/businessPro/getMatchUrl',
        method: 'post',
        data: options
    })
}

// 改版 活动中心列表
export function queryActivityCenterList(options) {
    return request({
        url: '/businessPro/queryActivityCenter',
        method: 'post',
        data: options
    })
}

// 活动中心类型
export function queryActivityCentertType(options) {
    return request({
        url: '/businessPro/selectRegionalActivities',
        method: 'post',
        data: options
    })
}

// 优秀作品详情
export function queryWorksDetail(options) {
    return request({
        url: '/businessPro/findByPortalWorkId',
        method: 'post',
        data: options
    })
}
