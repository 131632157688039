<template>
  <div class="noData">
    <img src="@/assets/imgs/nodata.png" width="339" alt="">
    <p style="font-size: 14px;margin-top:30px;color: #606978;" v-text="promptText"></p>
  </div>
</template>
<script>
export default {
  name:'noData',
  props:['promptText'],
  data(){
    return{
      
    }
  }
}
</script>
<style lang="scss" scoped>
  .noData{
    text-align: center;
    padding: 120px 0;
  }
</style>