<template>
    <div class="Event_Center" style="background-color: #F8F9FA;" v-cloak>
        
        <template v-if="PortalConfig.navigationMenuList && PortalConfig.navigationMenuList[navMenuSort].operateImg != null">
            <img
            :src="PortalConfig.navigationMenuList[navMenuSort].moduleCode == MODELCODE.ACTIVITY && PortalConfig.navigationMenuList[navMenuSort].operateImg != null? QiNiuPath + PortalConfig.navigationMenuList[navMenuSort].operateImg : defaultImg" >
        </template>
        <div class="fccBanner_box" v-if="fccFlag != '' && fccFlag != 'undefined'">
            <img src="../assets/imgs/fccBanner.png" alt="fccBanner" class="fccBanner">
            <img src="../assets/imgs/fccBtn.png" alt="" class="fccBtn" @click="toFccPage()">
        </div>
        <div class="type_box">
            <ul>
                <li 
                v-for="(item, index) in activeType" :key="index" 
                :class="currentType == index?'active_type': ''" 
                @click="selectType(index)">{{item.typeName}}</li>
            </ul>
        </div>
        
        <a-spin :spinning="spinning">
        <div class="EventHigh">
            <ActiveItem 
                v-for="(item, index) in matchList" 
                :key="index"
                :activeData="item"
                @toDetail="goMatchDetail"
                @toDetailt="goMatchDetailt"
                class="a-fade-in">
            </ActiveItem>
            
            <!-- <ul v-for="item in matchList" :key="item.matchId" @click="goMatchDetail(item.jumpUrl)" class="a-fade-in">
                <li><img :src="QiNiuPath+item.matchThumbnail"></li>
                <li class="list">
                    <h2>{{item.matchName}}<img :src=" item.status==1?require('../assets/imgs/start_icon.png'): item.status==2?require('../assets/imgs/process_icon.png'): require('../assets/imgs/end_icon.png') "></h2>
                    <p>{{item.matchInfo}}</p>
                    <h3><img src="../assets/imgs/calendar_icon.png">比赛时间：{{item.matchStartTime}}-{{item.matchEndTime}}</h3>
                </li>
            </ul> -->
            <!-- <p style="color:#9AA7B9;font-size:14px;text-align: center;padding-bottom: 20px;" v-text="matchPageSize*matchCurrentPage<matchTotal?'更多内容加载中':'没有更多内容了'"></p> -->
        </div>
        </a-spin>
        
        <div class="pages">
            <div v-show="totalPage > 1">
                <a-pagination v-model="currentPage" :total="totalPage * 10" @change="nextPage"/>
            </div>
        </div>
        <div v-if="matchList.length == 0">
            <no-data promptText="暂无活动！"/>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import { queryMatchList, queryMatchDetailUrl, queryActivityCentertType, queryActivityCenterList} from '@/apis/EventCenter.js';
    import NoData from '@/pages/NoData.vue';
    import ActiveItem from '@/components/common/activeitem';

    export default {
        name: "Event_Center",
        data() {
            return {
                matchPageSize: 10,
                matchCurrentPage: 1,
                matchTotal: 0,
                matchList: [],
                fccUrl:'',
                currentType: 0, // 当前分类
                defaultPage: 1, // 默认页数
                currentPage: 1, // 当前页数
                totalPage: 0, // 列表总页数
                navMenuSort: 1, //导航菜单排序
                defaultImg: require("../assets/imgs/event_banner.png"),
                activeType: [], // 活动类型
                activeTypeId: '', // 活动类型，默认全部
                spinning: false, // 加载动画
                fccFlag:'https://www.qingsaishi.cn/index.html'
            };
        },
        computed: {
            ...mapState({
                QiNiuPath: state => state.indexStore.QiNiuPath,
                PortalConfig: state => state.indexStore.PortalConfig,
                MODELCODE: state => state.indexStore.MODELCODE
            }),

        },
        created() {
            this.getActiveType()
            .then(typeId => {
                this.getActiveList(this.currentPage, typeId);
            })
        },
        watch: {
            // fccUrl() {
            //     let that = this;
            //     if (that.fccUrl != null && that.fccUrl!="") {
            //         window.open(that.fccUrl);
            //     }
            //     that.fccUrl = "";
            // },
            
        },
        mounted() {
            this.navMenuSort = Number(localStorage.getItem('sort'));
            // this.fccFlag = localStorage.getItem('fccFlag')
            // window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
        },
        components:{
            'NoData':NoData,
            ActiveItem
        },
        methods: {
            toFccPage() {
                window.open(this.fccFlag)
            },
            handleScroll() {
                let that = this;
                if ((document.documentElement.scrollTop + window.innerHeight + 100) >= document.documentElement.scrollHeight) {
                    if ((that.matchPageSize * that.matchCurrentPage) < that.matchTotal) {
                        that.moreData();
                    }
                }
            },
            getMatchList: function () {
                let that = this;
                let params = {
                    currentPage: that.matchCurrentPage,
                    pageSize: that.matchPageSize
                };
                that.$nextTick(() => {
                    queryMatchList(params).then(res => {
                        let msg = res != undefined && res.data != undefined && res.data != null ? res.data : null;
                        if (msg != null && msg.status == 0) {status
                            that.matchTotal = msg.result ? msg.result.total : 0;
                            if (that.matchCurrentPage == 1) {
                                that.matchList = [];
                            }
                            let tempMatchList = msg.result && msg.result.matchList ? [...msg.result.matchList] : [];
                            for (var i = 0; i < tempMatchList.length; i++) {
                                that.matchList.push(tempMatchList[i]);
                            }
                        }
                    })
                })
            },
            moreData: function () {
                let that = this;
                that.matchCurrentPage++;
                that.getMatchList();
            },
            goMatchDetail(fccUrl) {
                let that = this;
                // debugger
                window.open(fccUrl);
                //var tempwindow = window.open('_blank');
                //queryMatchDetailUrl(params).then(res => {
                //    let msg = res != undefined && res.data != undefined && res.data != null ? res.data : null;
                //    if (msg != null && msg.status == 0 && msg.result != null) {
                //        //that.fccUrl = msg.result.fccUrl;
                //        tempwindow.location = msg.result.fccUrl;
                //        //var a = document.createElement("a");
                //        //a.setAttribute("href", msg.result.fccUrl);
                //        //a.setAttribute("target", "_blank");
                //        //a.setAttribute("id", "openwin");
                //        //document.body.appendChild(a);
                //        //a.click();

                //        //window.open(msg.result.fccUrl);
                //    }
                //});

            },
            goMatchDetailt (pclive) {
                console.log(111111111222)
                window.open(pclive);
            },
            // 获取活动列表
            getActiveList(page, typeId) {
                let params = {
                    currentPage: page,
                    pageSize: 10,
                    activeTypeId: typeId,
                };
                queryActivityCenterList(params).then(res => {
                    if(res.data.status == 0){
                        let back = res.data.result;
                        this.totalPage = back.pages;
                        this.matchList = back.matchList;
                        setTimeout(() => {
                            this.spinning = false
                        },200)
                    }else{
                        this.spinning = false
                    }
                }).catch(err => {
                    this.spinning = false
                })
            },
            // 获取活动类型
            getActiveType() {
                return new Promise((resolve, reject) => {
                    let params = {};
                    queryActivityCentertType(params).then(res => {
                        if(res.data.status == 0){
                            this.activeTypeId = res.data.result.typeList[0].id;
                            let back = res.data.result;
                            this.activeType = back.typeList;
                            resolve(back.typeList[0].id)
                        }
                    })
                })
                
            },
            // 选择类型
            selectType(index) {
                // 活动类型id
                let activeId = this.activeType[index].id;
                this.currentType = index;
                this.activeTypeId = activeId;
                this.currentPage = 1; //当前页数
                // this.defaultPage = 1; //默认页数
                this.spinning = true
                this.getActiveList(this.currentPage, activeId)
                
            },
            // 分页：页数改变执行  回调参数(page, pageSize)
            async nextPage(changePage) {
                this.spinning = true
                await this.getActiveList(changePage, this.activeTypeId);
                this.scrollTop();
            },
            // 滚动到顶部
            scrollTop() {  window.scrollTo(0, 0) },
        },
    }
</script>

<style lang="scss" scoped>
    @import "../style/mixin.scss";

    [v-cloak]{
        display: none;
    }
    .Event_Center {
        width: 100%;
        height: auto;
        overflow-y: hidden;
        min-width: 1200px;
    }

    .Event_Center > img {
        width: 100%;
        // height: 480px;
    }

    .EventHigh {
        width: 1200px;
        margin: 0 auto;
        /* padding: 15px; */
        margin-top: 40px;
        // display: flex;
        // flex-flow: row wrap;
        // justify-content: space-between;
        // position: relative;
    }

    .EventHigh  ul {
        overflow-y: hidden;
        /* padding: 20px; */
        height: auto;
        background: rgba(255,255,255,1);
        /* box-shadow: 3px 5px 14px 0px #E9E9EA; */
        border-radius: 6px;
        margin-bottom: 30px;
        width: 580px;
        cursor: pointer;
        transition: all 0.4s ease;
    }

    .EventHigh > ul:hover {
        -moz-box-shadow:0 0 14px rgba(0,0,0,0.1);
        -webkit-box-shadow:0 0 14px rgba(0,0,0,0.1);
        box-shadow:0 0 14px rgba(0,0,0,0.1);
        transform: translateY(-4px);
    }

    .EventHigh > ul > li {
        // float: left;
    }

    .EventHigh > ul > li > img {
        width: 100%;
        height: 386px;
    }

    .EventHigh .list {
        /* margin-left: 50px; */
        /* width: 760px; */
        position: relative;
        min-height: 197px;
        padding: 16px 0 24px 0;
        /* height: 213px; */
    }

    .EventHigh .list > h2 {
        font-size: 24px;
        color: #333A48;
        letter-spacing: 1px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        padding: 0px 20px 12px 20px;
        
    }

    .EventHigh .list > h2 > img {
        vertical-align: -2px;
        margin-left: 10px;
    }

    .EventHigh .list > p {
        color: #9AA7B9;
        font-size: 16px;
        line-height: 30px;
        padding: 0 20px;
        /* margin-top: 15px; */
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        margin-bottom: 19px;
        height: 90px;
    }

    .EventHigh .list > h3 {
        padding: 0 20px;
        color: #9AA7B9;
        font-size: 16px;
    }

    .EventHigh .list > h3 > img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        vertical-align: -3px;
    }

    .fccBanner_box {
        width: 1200px;
        margin: 42px auto 42px auto;
        position: relative;
        .fccBanner{
            width: 1200px;
            height: 181px;
        }
        .fccBtn{
            width: 144px;
            height: 51px;
            position: absolute;
            top: 36%;
            right: 6%;
            cursor: pointer;
        }
    }
    .type_box{
        width: 1200px;
        margin: 28px auto 0 auto;
        ul{
            display: flex;
            flex-flow: row wrap;
            li{
                padding: 5px 20px;
                background: #EFF0F3;
                border-radius: 15px;
                @include sc(14px, #606978);
                margin-right: 14px;
                margin-bottom: 10px;
                cursor: pointer;
                transition: all 0.2s ease;
            }
            .active_type{
                color: #fff;
                background: #5D5AED;
            }
        }
    }

    .pages{
        text-align: center;
        margin-bottom: 70px;
        height: 32px;
    }

    .a-fade-in{
        animation: fade-in 0.4s linear;
    }

    @include keyframes(fade-in){
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
</style>
